import { Layout, Menu } from "antd";
import {
  ArrowRightLeft,
  BadgeInfo,
  Cable,
  CircleGauge,
  Cog,
  Component,
  DollarSign,
  Fan,
  FileSpreadsheet,
  Handshake,
  Headset,
  LandPlot,
  MapPin,
  MapPinIcon,
  Package,
  PieChart,
  Plane,
  PlaneLanding,
  Scale,
  Shield,
  Sparkle,
  SquareUser,
  Tag,
  Tally1,
  Tally2,
  Tally3,
  TrendingUp,
  Type,
  User2,
  UserPlus,
  Users,
  UsersRound,
  Wrench,
} from "lucide-react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const getLevelKeys = (items1) => {
  const key = {};
  const func = (items2, level = 1) => {
    items2.forEach((item) => {
      if (item.key) {
        key[item.key] = level;
      }
      if (item.children) {
        func(item.children, level + 1);
      }
    });
  };
  func(items1);
  return key;
};

const Sidebar = () => {
  const { pathname } = useLocation();
  const activeKey = pathname === "/" ? "/" : pathname.split("/")[1];

  const [stateOpenKeys, setStateOpenKeys] = useState(["2", "23"]);
  const onOpenChange = (openKeys) => {
    const currentOpenKey = openKeys.find(
      (key) => stateOpenKeys.indexOf(key) === -1,
    );
    // open
    if (currentOpenKey !== undefined) {
      const repeatIndex = openKeys
        .filter((key) => key !== currentOpenKey)
        .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);
      setStateOpenKeys(
        openKeys
          // remove repeat key
          .filter((_, index) => index !== repeatIndex)
          // remove current level all child
          .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey]),
      );
    } else {
      // close
      setStateOpenKeys(openKeys);
    }
  };

  const items = [
    {
      key: "/",
      icon: (
        <div>
          <PieChart size={18} />
        </div>
      ),
      label: <Link to="/">Dashboard</Link>,
    },
    {
      key: "user",
      icon: (
        <div>
          <Users size={18} />
        </div>
      ),
      label: <Link to="/user">Users</Link>,
    },
    {
      key: "spacs",
      icon: (
        <div>
          <Package size={18} />
        </div>
      ),
      label: <Link to="/vehicle-specs">Vehicle Specs</Link>,
    },
    {
      key: "sample",
      icon: (
        <div>
          <CircleGauge size={18} />
        </div>
      ),
      label: <Link to="/upload-sample">Sample File</Link>,
    },
    {
      key: "audit",
      icon: (
        <div>
          <Cog size={18} />
        </div>
      ),
      label: <Link to="/audit">Audit</Link>,
    },
    {
      key: "rule",
      icon: (
        <div>
          <Shield size={18} />
        </div>
      ),
      label: <Link to="/rule">Rule</Link>,
    },
  ];
  const levelKeys = getLevelKeys(items);

  return (
    
    <Layout.Sider
      breakpoint="lg"
      collapsedWidth="0"
      theme="light"
      className="border-r-2"
      width={213}
    >
      <Link
        to="/"
        className="mx-4 mb-4 flex flex-col items-center justify-center border-b py-2 "
      >
        <div className="h-10 w-28 ">
          {/* <img
            src="/flysky/images/logo.png"
            alt="logo"
            className="h-full w-full cursor-pointer object-contain"
          /> */}
        </div>

        <h5 className="text-blue-1 text-xl font-bold italic text-red-600 text-red-700">
          Auto Matcher
        </h5>
      </Link>
      <Menu
        mode="inline"
        selectedKeys={[activeKey]}
        onClick={() =>
          window.scroll({
            top: 0,
            behavior: "smooth",
          })
        }
        openKeys={stateOpenKeys}
        onOpenChange={onOpenChange}
        className="custom-menu border-none"
        items={items}
      />
    </Layout.Sider>
  );
};
export default Sidebar;
