import { Avatar, Button, Dropdown, Form, Modal, Select, Space } from "antd";
import { ChevronDown, LogOut, ShieldPlus, User2 } from "lucide-react";
import { useNavigate } from "react-router-dom";
import useError from "../../hooks/useError";
import { useState } from "react";
// import { postSession } from "../../store/features/authSlice";
import toast from "react-hot-toast";
import { useUserContext } from "../../context/users/users";

const Logout = () => {
  const navigate = useNavigate();
  const { loggedin_user_detail } = useUserContext();

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  return (
    <div className="flex w-full items-center gap-2" onClick={handleLogout}>
      <LogOut size={16} />
      <span>Logout</span>
    </div>
  );
};

const items = [
  {
    key: "1",
    label: (
      <div className="flex w-full items-center gap-2">
        <User2 size={16} />
        <span>Profile</span>
      </div>
    ),
  },
  {
    key: "2",
    danger: true,
    label: <Logout />,
  },
];

const Navbar = () => {
  const handleError = useError();


  return (
    <div className="flex min-h-14 items-center justify-end gap-4 border-b bg-red-700 p-1 px-8">
      <h2 className="text-blue-1 ml-4 mr-auto block text-ellipsis text-nowrap text-xl font-bold italic lg:hidden text-gray-50">
      Auto Matcher
      </h2>

      <div className="flex items-center gap-2">
        <Dropdown
          menu={{
            items,
          }}
        >
          <div className="flex cursor-pointer items-center gap-2 rounded-lg p-1 px-4 hover:bg-red-800">
            <Avatar icon={<User2 />} size={40} />
            <div className="hidden sm:block">
              <div className="flex items-center gap-2 text-gray-50">
                <span>User</span> <ChevronDown size={16} />
              </div>
            </div>
          </div>
        </Dropdown>
      </div>
    </div>
  );
};
export default Navbar;
