import { WifiOff } from "lucide-react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const useError = () => {
  const navigate = useNavigate();
  const handleError = (error, showMessage = true) => {
    // if:  if user unauthorize then logout and redirect to login page
    // else if: if network error then show error message
    // else: show error message

    if (error?.response?.status === 401) {
      showMessage &&
        toast.error(error?.response?.data?.error || "You need to login again");
      localStorage.clear();
      navigate("/login");
    } else if (error?.message === "Network Error") {
      showMessage &&
        toast.error("Please check your internet connection.", {
          icon: <WifiOff size={20} color="red" />,
        });
    } else {
      showMessage &&
        toast.error(error?.response?.data?.error || "Something went wrong");
        console.log(error?.response?.data?.error);
        
    }
  };

  return handleError;
};

export default useError;
