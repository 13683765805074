import React, { createContext, useContext, useState } from "react";

export const userContext = createContext({});
export const useUserContext = () => useContext(userContext);

const UserContextProvider = ({ children }) => {
  const [login_id, set_login_id] = useState(null);
  const [login_password, set_login_password] = useState(null);
  const [full_name, set_full_name] = useState('');
  const [is_admin, set_is_admin] = useState(0);
  const [is_active, set_is_active] = useState(0);

  const resetUserContext = () => {
    set_login_id("");
    set_login_password("");
    set_full_name("");
    set_is_admin(false);
    set_is_active(false);
  };

  const [loggedin_user_detail, set_loggedin_user_detail] = useState({})

  const values = {
    login_id, 
    set_login_id,
    login_password, 
    set_login_password,
    full_name, 
    set_full_name,
    is_admin, 
    set_is_admin,
    is_active, 
    set_is_active,
    loggedin_user_detail, 
    set_loggedin_user_detail,
    resetUserContext,
  };

  return <userContext.Provider value={values}>{children}</userContext.Provider>;
};

export default UserContextProvider;
