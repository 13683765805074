import { Button, Result } from "antd";
import { Link } from "react-router-dom";

const ErrorPage = () => {
  return (
    <Result
      status="warning"
      title="Oops! Something went wrong."
      extra={
        <Link to="..">
          <Button type="primary" key="console" className="custom-primary-button">
            Try to go back
          </Button>
        </Link>
      }
    />
  );
};
export default ErrorPage;
