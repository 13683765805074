import { Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import { Navigate, Outlet } from "react-router-dom";
import Footer from "../components/Layout/Footer";
import Navbar from "../components/Layout/Navbar";
import Sidebar from "../components/Layout/Sidebar";

const MainLayout = () => {
  if (!localStorage.getItem("api_token")) return <Navigate to="/login" />;

  return (
    <Layout className="min-h-screen">
      <Sidebar />
      <Layout>
        <Navbar />
        <Content className="bg-gray-50 p-4">
          <Outlet />
        </Content>
        <Footer />
      </Layout>
    </Layout>
  );
};
export default MainLayout;
